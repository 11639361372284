import React from 'react';
import { Link } from 'react-router-dom';

const SideBar = () => (
  <section id="sidebar">
    {/*<section id="intro">*/}
    {/*  <Link to="/" className="logo">*/}
    {/*    <img src={`${PUBLIC_URL}/images/me.jpg`} alt="" />*/}
    {/*  </Link>*/}
    {/*  <header>*/}
    {/*    <h2>Nabraj Khadka</h2>*/}
    {/*    <p><a href={`mailto:${config.email.primary}`}>{config.email.primary}</a></p>*/}
    {/*  </header>*/}
    {/*</section>*/}

    <section className="blurb">
      <h2>About</h2>
      <p>With years of experience delivering impactful applications across fintech, edtech, e-commerce, and medical sectors. I have successfully collaborated with global teams and handled every stage of development to create user-friendly and secure apps. I'm actively involved in open-source contributions and freelancing projects. If you have an idea for an app, let's discuss! </p>
      <ul className="actions">
        <li>
            <div className="button">
                <a
                    href="https://drive.google.com/file/d/1pokEzh33MlqHqZPB0rgqHEXmGaMto7SAdq4aMT_2EZc/view"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    View My Resume
                </a>
            </div>
        </li>
      </ul>
    </section>

      <section id="footer">
          <h4 className="copyright">&copy; Nabraj Khadka <Link to="/">{new Date().getFullYear()}</Link>.</h4>
      </section>
  </section>
);

export default SideBar;
